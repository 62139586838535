// helper file to import all the finger animation images we need from the asset pipeline
// and map them to useable filenames
import leftHome from '~Images/finger_animations/finger-anim-left-hand-HOME.png';
import tab from '~Images/finger_animations/finger-anim-left-hand-TAB.png';
import q from '~Images/finger_animations/finger-anim-left-hand-Q.png';
// import capslock from '~Images/finger_animations/finger-anim-left-hand-CAPSLOCK.png';
import a from '~Images/finger_animations/finger-anim-left-hand-A.png';
import z from '~Images/finger_animations/finger-anim-left-hand-Z.png';
import w from '~Images/finger_animations/finger-anim-left-hand-W.png';
import s from '~Images/finger_animations/finger-anim-left-hand-S.png';
import x from '~Images/finger_animations/finger-anim-left-hand-X.png';
import e from '~Images/finger_animations/finger-anim-left-hand-E.png';
import d from '~Images/finger_animations/finger-anim-left-hand-D.png';
import c from '~Images/finger_animations/finger-anim-left-hand-C.png';
import r from '~Images/finger_animations/finger-anim-left-hand-R.png';
import f from '~Images/finger_animations/finger-anim-left-hand-F.png';
import v from '~Images/finger_animations/finger-anim-left-hand-V.png';
import t from '~Images/finger_animations/finger-anim-left-hand-T.png';
import g from '~Images/finger_animations/finger-anim-left-hand-G.png';
import b from '~Images/finger_animations/finger-anim-left-hand-B.png';
import rightHome from '~Images/finger_animations/finger-anim-right-hand-HOME.png';
import p from '~Images/finger_animations/finger-anim-right-hand-P.png';
// import Colon from '~Images/animations/finger-anim-right-hand-COLON.png';
import backspace from '~Images/finger_animations/finger-anim-right-hand-BACKSPACE.png';
import o from '~Images/finger_animations/finger-anim-right-hand-O.png';
import l from '~Images/finger_animations/finger-anim-right-hand-L.png';
import i from '~Images/finger_animations/finger-anim-right-hand-I.png';
import k from '~Images/finger_animations/finger-anim-right-hand-K.png';
import u from '~Images/finger_animations/finger-anim-right-hand-U.png';
import j from '~Images/finger_animations/finger-anim-right-hand-J.png';
import m from '~Images/finger_animations/finger-anim-right-hand-M.png';
import y from '~Images/finger_animations/finger-anim-right-hand-Y.png';
import h from '~Images/finger_animations/finger-anim-right-hand-H.png';
import n from '~Images/finger_animations/finger-anim-right-hand-N.png';
import space from '~Images/finger_animations/finger-anim-right-hand-SPACE.png';

// special chars
import backtick from '~Images/finger_animations/finger-anim-left-hand-BACKTICK.png';
import one from '~Images/finger_animations/finger-anim-left-hand-1.png';
import two from '~Images/finger_animations/finger-anim-left-hand-2.png';
import three from '~Images/finger_animations/finger-anim-left-hand-3.png';
import four from '~Images/finger_animations/finger-anim-left-hand-4.png';
import five from '~Images/finger_animations/finger-anim-left-hand-5.png';
// import six from '~Images/finger_animations/finger-anim-left-hand-6.png';
// import seven from '~Images/finger_animations/finger-anim-right-hand-7.png';
// import eight from '~Images/finger_animations/finger-anim-right-hand-8.png';
// import nine from '~Images/finger_animations/finger-anim-right-hand-9.png';
// import zero from '~Images/finger_animations/finger-anim-right-hand-0.png';
import dash from '~Images/finger_animations/finger-anim-right-hand-DASH.png';
import equal from '~Images/finger_animations/finger-anim-right-hand-EQUAL.png';

import apostrophe from '~Images/finger_animations/finger-anim-right-hand-APOSTROPHE.png';
import semiColon from '~Images/finger_animations/finger-anim-right-hand-SEMICOLON.png';
import period from '~Images/finger_animations/finger-anim-right-hand-PERIOD.png';
import comma from '~Images/finger_animations/finger-anim-right-hand-COMMA.png';
import enter from '~Images/finger_animations/finger-anim-right-hand-ENTER.png';
import rightSquareBracket from '~Images/finger_animations/finger-anim-right-hand-RIGHTSQUAREBRACKET.png';
// import leftSquareBracket from '~Images/finger_animations/finger-anim-right-hand-LEFTSQUAREBRACKET.png';
import backslash from '~Images/finger_animations/finger-anim-right-hand-BACKSLASH.png';
import shift from '~Images/finger_animations/finger-anim-left-hand-LEFT-SHIFT.png';
import rightShift from '~Images/finger_animations/finger-anim-right-hand-RIGHT-SHIFT.png';

export default {
  // shift keys
  shift,
  leftShift: shift,
  rightShift: rightShift,

  // special chars
  '`': backtick,
  1: one,
  2: two,
  3: three,
  4: four,
  5: five,
  // 6: six,
  // 7: seven,
  // 8: eight,
  // 9: nine,
  // 0: zero,
  '-': dash,
  '=': equal,

  "'": apostrophe,
  ';': semiColon,
  '.': period,
  ',': comma,
  enter,
  // '[': leftSquareBracket,
  ']': rightSquareBracket,
  '\\': backslash,
  tab,

  // normal chars
  leftHome,
  q,
  // capslock,
  a,
  z,
  w,
  s,
  x,
  e,
  d,
  c,
  r,
  f,
  v,
  t,
  g,
  b,
  rightHome,
  p,
  backspace,
  o,
  l,
  i,
  k,
  u,
  j,
  m,
  y,
  h,
  n,
  ' ': space,
};
