// angularStateName: url

const angularStates = {
  'app.home': '/home',
  'app.account': '/account',
  'app.settings/my_classes': '/settings/my_classes',
  'app.keyboarding.profile': '/profile',
  'app.keyboarding.games': '/games',
  'app.keyboarding.practice_timed_writing': '/timed_writing',
  'app.keyboarding.supervised_timed_writing': '/timed_writing/{notification_id}',
  'app.keyboarding.transcription': '/transcription',
  'app.keyboarding.level': '/level/{level}',
  'app.admin.load_test': '/load_test',
  'app.admin.keyboard_test': '/admin/keyboard_test/{text}',
  'app.admin.dashboard': '/admin/dashboard',
  'app.admin.commission': '/admin/commission',
  'app.admin.practice_text': '/admin/practice_text',
  'app.instructor.document_assignment_index': '/document_assignments',
  'app.admin.contacts_directory': '/admin/contacts_directory',
  // Note: The two states below are nested. If changed, be sure the change appropriately
  // in the reactStates object, too.
  assignment_creator: '/admin/assignment_creator',
  'assignment_creator.index': '/',
  'app.instructor.class': '/class',
  'app.instructor.accommodation': '/instructor/accommodation',
  'app.instructor.transcription_copy': '/instructor/transcription_copy',
  'app.instructor.tutorials': '/instructor/tutorials',
  'app.instructor.learn_document_production': '/learn_document_production',
  // Note: The two states below are nested. If changed, be sure the change appropriately
  // in the reactStates object, too.
  'app.instructor.course_templates': '/course_templates',
  'app.instructor.course_templates.index': '',
  // Note: The two states below are nested. If changed, be sure the change appropriately
  // in the reactStates object, too.
  course_builder: '/course_builder',
  'course_builder.index': '',
  admin_course_templates: '/admin/course_templates',
  'app.keyboarding.invigilation_centre': '/invigilation_centre',
  'app.subscriptions.document_production': '/document_production',
  'app.keyboarding.certificates/view': '/certificates/view',
  'app.keyboarding.competition': '/games/championship/{contest_id}',
  // DOC_PRODUCTION_STATES!
  'app.documents.document_production_activity': '/document_production/assignment/{document_assignment_id}/{notification_id}',
  'app.documents.document_production_activity.pre': '/pre',
};

// statesUrls have angular state names as keys, and their urls as values.
// e.g. {"app.home": "/home", ...}
// we need the keys to be of the form: "/#/home"
let reactStates = {};
Object.keys(angularStates).forEach((element) => {
  reactStates[element] = '/#' + angularStates[element];
});

// Manually fix the nested states so that they work properly
// slice off the '/#' that was added in the child state
reactStates['assignment_creator.index'] = reactStates['assignment_creator'] + reactStates['assignment_creator.index'].slice(2);

reactStates['app.instructor.course_templates.index'] =
  reactStates['app.instructor.course_templates'] + reactStates['app.instructor.course_templates.index'].slice(2);

reactStates['course_builder.index'] = reactStates['course_builder'] + reactStates['course_builder.index'].slice(2);

reactStates['app.documents.document_production_activity.pre'] =
  reactStates['app.documents.document_production_activity'] +
  reactStates['app.documents.document_production_activity.pre'].slice(2);

export { angularStates, reactStates };
