import { keycodeMap, keycodeMapWithShift } from './keycodeMaps.js';

/**
 * Includes a set of utility functions useful for handling keyboard input within
 * typing activities.
 */
class KeyEventHandler {
  constructor() {
    this.hasBeenWarned = false;
  }

  /**
   * Should be called if we're expecting user input via the keyboard. Accepts and enhances an event
   * object, taking care of some browser irregularities while added useful properties. Prevents scrolling,
   * browser events, etc. Generally, should be used within a keyEventHandler that ignores the event if
   * this method returns true. Ex: `if (keyEventHandler.getInput(e, 'a')) return;`
   * @param {Event} e - An event object from the browser
   * @param {string} targetKey - The target key, e.g. "k". This is added to the event object.
   * @returns {Bool} - Whether the input should be ignored (e.g. if shift key was pressed).
   */
  getInput(e, targetKey) {
    let shouldIgnoreEvent = false;
    // gets an enhanced event object with keyPressed and keyPressedLowcase attributes
    this.getKeyPressed(e, targetKey);
    // do nothing if any these keys (e.g. numlock) are typed
    if (this.shouldDoNothing(e)) shouldIgnoreEvent = true;
    // prevent scrolling, going 'Back', and other browser keyboard shortcuts
    this.shouldPreventDefault(e);
    // Don't count modifier keys as keystrokes
    if (this.noOutputFromTheseKeys(e)) shouldIgnoreEvent = true;
    return shouldIgnoreEvent;
  }

  /**
   * Accepts and enhances an event object, taking care of some browser irregularities while added useful
   * properties.
   * @param {Event} e - An event object from the browser
   * @param {string} targetKey - The target key, e.g. "k". This is added to the event object.
   * @returns {Object} - An enhanced event with keyPressed and keyPressLowcase attributes
   */
  getKeyPressed(e, targetKey) {
    let keyPressed;
    // Transform the character if it's non-alpha
    if (e.key) {
      keyPressed = e.key;
      if (keyPressed === 'Spacebar') {
        keyPressed = ' ';
      } // Fix for internet explorer
      // Just like Spacebar, the idea here is to convert the 'Enter' key code
      // to what the Enter key represents as a single ASCII
      // This is a custom char to represent an enter key, and has to be consistent
      // with what is found in the 'text to type' passed to TypingInput service
      if (keyPressed === 'Enter') {
        keyPressed = '↵';
      }
    } else if (e.keyCode) {
      // use keyCode | LEGACY BROWSERS ONLY
      keyPressed = this.keyFromKeyCode(e);
    }
    e.keyPressed = transformBadKeys(keyPressed);
    e.targetKey = transformBadKeys(targetKey);
    e.keyPressedLowcase = keyPressed.toLowerCase();
    e.timeStampAdjusted = Date.now();
    e.isCorrect = e.keyPressed === e.targetKey;
    // Although browsers a event.timeStamp property, which should be the same as performance.now,
    // some implementation of Safari have enormous and incorrect event.timeStamp properties.
    // However, performance.now() still seems to work correctly so we add it here.
    // Also: IE11 seems to use event.timeStamp as Date.now()
    e.performanceNow = performance.now();
    e.performanceNowInt = Math.floor(e.performanceNow);
    e.strip = this.strip;

    // TODO: Call Bugsnag if we get one of those weird errors
    // if (e.keyPressed === 'Unidentified') {
    //   BugsnagNotify.notify(new Error('Unidentified key pressed'), (event) => {
    //     event.context = 'Typing Input Service - Unidentified key pressed';
    //     event.addMetadata('eventList', this._eventList);
    //     event.addMetadata('events', this.events);
    //   });
    // }

    return e;
  }

  /**
   * Method is attached to the enhanced event object through the getKeyPressed method. In effect,
   * this method returns a flat object useful for storing or serialization to JSON.
   * @returns {Object} - A simplified event object
   */
  strip() {
    return {
      keyPressed: this.keyPressed,
      keyPressedLowcase: this.keyPressedLowcase,
      targetKey: this.targetKey,
      timeStampAdjusted: this.timeStampAdjusted,
      timeStamp: this.timeStamp,
      performanceNow: this.performanceNow,
      performanceNowInt: this.performanceNowInt,
      type: this.type,
      shiftKey: this.shiftKey,
      keyCode: this.keyCode,
      code: this.code,
      metaKey: this.metaKey,
      ctrlKey: this.ctrlKey,
    };
  }

  /**
   * Runs `preventDefault()` if we need to block the default behaviour of the key. This
   * is needed on keys like the backspace ('Back' in old browsers), etc.
   * @param {Event} e - A keydown/keyup event
   */
  shouldPreventDefault(e) {
    // Prevent default actions of backspace (8), enter(13), space(32), ...
    //      single-quote(222) since it opens a finder window in firefox
    //      "/" since it jumps to debugger finder in Firefox
    // Don't block the keyboard if an input is in focus
    if (e.target.localName === 'input') return;
    const blockedKeys = ['Tab', 'Backspace', 'Enter', ' ', "'", '/'];
    if (blockedKeys.indexOf(e.keyPressed) >= 0) {
      e.preventDefault();
      return true;
    } else {
      return false;
    }
  }

  noOutputFromTheseKeys(e) {
    // MAC     /      WINDOWS  -  KEYCODE   (reference list: http://unixpapa.com/js/key.html)
    // left command  / left windows  -  91
    // start         / right windows -  92
    // right command / windows       -  93
    // Firefox: Right Command Key
    // "" / ""                       -  255 (null key. various uses)
    const noOutputKeys = [9, 16, 17, 18, 20, 91, 92, 93, 224, 255];
    if (noOutputKeys.indexOf(e.keyCode) >= 0) return true;
    // protects against AudioVolumeMute, AudioVolumeUp, Etc.
    const noOutputKeyPrefixes = ['Audio', 'Microphone'];
    // AudioVolume Mute              -  173
    // AudioVolume Down              -  174
    // AudioVolume Up                -  175
    const isAudioKey = noOutputKeyPrefixes.some((prefix) => e.key.startsWith(prefix));
    if (isAudioKey) return true;

    // Lets check if the user is zooming in/out
    //  Note: metaKey corresponds to 'command' on a mac, windows key on a pc.
    //          so we have to check for both ctrl and metaKey
    if (e.keyPressed === '=' && (e.metaKey || e.ctrlKey)) return true;
    if (e.keyPressed === '-' && (e.metaKey || e.ctrlKey)) return true;

    return false;
  }

  /**
   *
   * @param {*} keyPressedLowcase - The key that was pressed
   * @returns {boolean} - Determines whether or not to completely ignore the keystroke
   */
  shouldDoNothing({ keyPressedLowcase }) {
    const shouldDoNothingKeys = ['numlock'];
    // we wrap this in an if statement because of an error in Chrome
    // where the browser auto-populates a login form and calls this function
    // with little data in the event
    // ignore numlock key presses, for example
    return keyPressedLowcase && shouldDoNothingKeys.indexOf(keyPressedLowcase) > -1;
  }

  /**
   * Helps normalize browser anomolies using keycodes
   * @param {Event} e  - A keydown/keyup event object
   * @returns {string} - The key that was pressed
   */
  keyFromKeyCode(e) {
    // warn that we're using keyCodes
    if (!this.hasBeenWarned) {
      console.warn('Warning: Using legacy keycodes to determine keypresses.');
      this.hasBeenWarned = true;
    }

    let key;
    if (e.keyCode < 60 || e.keyCode > 90) {
      if (e.shiftKey) {
        key = keycodeMapWithShift[e.keyCode];
      } else {
        key = keycodeMap[e.keyCode];
      }
    }

    // Set equal to an empty string if a key was pressed that we don't care about
    if (typeof key === 'undefined') {
      key = '';
    }

    // Return the lower case if shift
    if (!e.shiftKey) {
      key = key.toLowerCase();
    }
    return key;
  }
}

const badKeysToTransform = {
  '—': '-', // weird dash -> regular dash
  '–': '-', // weird dash -> regular dash
  '‘': "'", // weird single quote -> single quote
  '’': "'", // weird single quote -> single quote
  '“': '"', // weird double quote -> double quote
  '”': '"', // weird double quote -> double quote
};
function transformBadKeys(keyPress) {
  return badKeysToTransform[keyPress] || keyPress;
}

export default KeyEventHandler;
