import { format } from 'date-fns';
import { reactStates } from '../../../angularjs/statesUrls';
import { getDatabase, push, ref } from 'firebase/database';

/**
 * Accepts an object returned from Firebase's realtime database, flattens it to an array
 * and adds some handy properties.
 *
 * For example: ```{"-Ej28u...": {attibutes} }``` ->
 * ```{firebaseKey: "-Ej2...", ...attributes}```
 * @param {object} firebaseObj An object returned from firebase's realtime databse
 * @param {number} serverTimeSystemTimeDiff time difference between browser and server at
 * load time
 * @returns {object[]} A flattend array of firebaseObj with added properties
 */
const firebaseNotificationsObjToArray = (firebaseObj, serverTimeSystemTimeDiff = 0) => {
  if (firebaseObj == null) return [];
  // accepts an object (notifications) and maps to an array for easy iteration
  // returns an array of objects

  return Object.entries(firebaseObj).map(([firebaseKey, notification]) => {
    const expiresOnDate = new Date(notification.expiresOn);
    const expiresOnDateStr = format(expiresOnDate, "EEE. MMM dd, yyyy 'at' h:mm aaaa");

    // older notifications have a redirectState with a value 'supervised_timed_writing', and some students have
    // re-activated their 2019 accounts in May 2024, causing them not to receive notifications.
    if (notification.redirectState === 'supervised_timed_writing') {
      // replace the notification state with the newer one
      notification.redirectState = 'app.keyboarding.supervised_timed_writing';
    }

    // If the notification is expired, set active to false
    //    If it is not expired, set a timeout to remove it later
    const _expired = isExpired(notification, expiresOnDate, serverTimeSystemTimeDiff);

    // use the reactStates that maps angular statues used by ui-router to URLs
    const reactUrl = reactStates[notification.redirectState]
      .replace('{notification_id}', firebaseKey)
      .replace('{document_assignment_id}', notification.document_assignment_id || '')
      .replace('{contest_id}', notification.contestFirebaseId || '');

    return { ...notification, firebaseKey, expiresOnDate, expiresOnDateStr, _expired, reactUrl };
  });
};

/**
 * Accepts any firebase object returned from snapshot.val(), flattens it to an array and
 * adds a key property
 *
 * For example: ```{"123": {attibutes} }``` ->
 * ```{firebaseKey: "123", ...attributes}```
 * @param {object} firebaseObj An object returned from firebase's realtime database (i.e. snapshot.val())
 * @param {string} keyName an optional name for the key
 * @returns {object[]} A flattend array of firebaseObj with added properties
 */
const firebaseObjToArray = (firebaseObj, keyName = 'firebaseKey') => {
  if (firebaseObj == null) return [];
  //
  return Object.entries(firebaseObj).map(([key, data]) => {
    return { ...data, [keyName]: key };
  });
};

/**
 * Accepts a number of days, hours, minutes and a server time offset and returns
 * a date object. Used a few places in our legacy AngularJS app.
 * For example:
 * dateInFuture(1, 12, 0) => a date that is 1 day and 12 hours in the future
 *
 * @param {number} plusDays Number of days to add
 * @param {number} plusHours Number of hours to add
 * @param {number} plusMinutes Number of minutes to add
 * @param {number} serverTimeSystemTimeDiff time difference between browser and server at
 * load time
 * @returns {Date} a javascript date that is in the future
 */
const dateInFuture = (plusDays, plusHours, plusMinutes, serverTimeSystemTimeDiff = 0) => {
  const now = Date.now() - serverTimeSystemTimeDiff;
  const addDays = (plusDays || 0) * 24 * 60 * 60 * 1000;
  const addHours = (plusHours || 0) * 60 * 60 * 1000;
  const addMinutes = (plusMinutes || 0) * 60 * 1000;
  const dateInFuture = new Date(now + addDays + addHours + addMinutes);
  return dateInFuture;
};

/**
 * Determines whether the notification is still active (true) or whether it has expired (false)
 * @param {Object} notification A notification object with an expiresOnDate property
 * @param {Date} expiresOnDate When the notification expires
 * @param {number} serverTimeSystemTimeDiff time difference between browser and server at
 * load time
 * @return {Boolean}
 */
function isExpired(notification, expiresOnDate, serverTimeSystemTimeDiff) {
  // if the active property has been deliberately set to false, for example if the user
  // has completed their activity, then don't set it to true again.
  // if (!notification.active) return false;

  // Note: This is more of an initialization since this class doesn't have access
  // to the server time that we'd need to normalize the 'now' variable. However, it
  // shouldn't matter since a timer will get set in the notification that will render this
  // as inactive anyways.
  const now = Date.now() - serverTimeSystemTimeDiff;
  const msToExpiry = expiresOnDate - now;

  if (msToExpiry <= 0) {
    return true;
  } else if (msToExpiry > 0) {
    // Set as active. Set a timeout to change in the future.
    return false;
    // $timeout(setActiveToFalse.bind(null, key), notification.age);
  }
}

/**
 * Accepts an array of userIds, a message object, and a reference to firebase. Produces an update
 * object structured to send this message to each user as a notification via firebase (using the multiple updates syntax),
 * an array of notificationIds, and an array of userIds.
 * NOTE: CURRENTLY ONLY USE FOR ANGULARJS
 * @param {number[]} userIds an array of user ids
 * @param {string} message an object that will be added to each user's notifications
 * @return {Object} { notificationIds: ['-Nf35jgg','-sdfsdfs'], userIds: [12, 20], updateObj: {'users/12/notifications/-Nf35jgg': {...message}, 'users/20/notificaitons/-sdfsdfs': {...message}}}
 */
function createFirebaseUpdateObjectForUsers(userIds, message) {
  const db = getDatabase();

  if (!message) throw new Error('No message provided.');

  let notificationIds = [];
  let updateObj = {};

  userIds.forEach((userId) => {
    let path = `notifications/users/${userId}`;
    const userRef = ref(db, `notifications/users/${userId}`);
    let notificationId = push(userRef).key;

    notificationIds.push(notificationId);
    // NOTE: an undefined value for message will throw an error when passed to firebase
    updateObj[`${path}/${notificationId}`] = message;
  });

  return {
    updateObj,
    notificationIds,
    userIds,
  };
}

export { firebaseNotificationsObjToArray, dateInFuture, createFirebaseUpdateObjectForUsers, firebaseObjToArray };
