import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defaultKeys, keyProperties } from '../data/defaultKeys';
import classNames from 'classnames';
import styles from './InteractiveKeyboardKey.module.scss';
const {
  fingerImage,
  fingerImageLeft,
  fingerImageRight,
  leftHand,
  rightHand,
  keyboardKey,
  keyboardKeyV2,
  fingerImageContainer,
  hideFingerImageContainer,
} = styles;

/* 
  This component displays two keys: 
    1. A shadow key, positioned absolutely, on top.
    2. A base key positioned underneath.

  This allows us to show an image between these keys while still being able
  to pick up keypresses with the shadow key that sits on top of the image.
*/
function InteractiveKeyboardKey(props) {
  // gets our default class list from defaultKeys
  const [klass, setKlass] = useState(defaultKeys[props.content]);
  const keyClass = props.version === 1 ? keyboardKey : keyboardKeyV2;

  // left or right
  const hand = keyProperties[props.content].hand;
  const showImage = props.showImage;

  // we need to make a copy here because we change the styling of the
  // klass variable on the known shadow key
  const shadowKlass = defaultKeys[props.content];
  let content = props.content === 'backspace' ? <>&larr;</> : props.content;
  // uppercase single letters
  content = content.length === 1 ? content.toUpperCase() : content;

  function onMouseEnter() {
    if (props.enableHover === false) return;
    setKlass({ ...klass, 'key-hovered': true });
  }

  function onMouseLeave() {
    if (props.enableHover === false) return;
    setKlass({ ...klass, 'key-hovered': false });
  }

  function onClick() {
    // let the parent component know this component has been clicked
    props.onClick && props.onClick(props.content);
  }

  // only add this attribute if we can click on the key
  let roleButton = {};
  if (props.enableHover) roleButton = { role: 'button' };
  let processedContent = content;
  if (content === 'leftShift' || content === 'rightShift') {
    processedContent = 'shift';
  }
  if (props.version > 1 && content === 'capslock') {
    processedContent = (
      <>
        <div>&bull;</div>
        <div>caps lock</div>
      </>
    );
  }
  if (content === ' ') {
    roleButton = { ...roleButton, 'aria-label': 'Space Key' };
  }

  return (
    <>
      <div className={keyClass} style={{ position: 'relative', display: 'inline-block' }}>
        {/* we add position relative so that our absolutely positioned (shadow key) element will reference this parent tag */}
        <div
          className={classNames(klass, { active: props.active && !props.hideHands })}
          style={{
            position: 'relative',
            zIndex: 1,
            // Overriding the 'active' color if present
            ...(props.spotlightColor ? { backgroundColor: props.spotlightColor, borderColor: props.spotlightColor } : {}),
          }}
        >
          {/* NOTE: don't need to render the digit here anymore because we are always rendering the shadow keyboard now */}
        </div>
        {/* 
        The shadow keyboard (below) is positioned absolutely on top of the keyboard above. This way we can 
        pickup mouseover events that may be on top of the finger animation images. Note: we want to display the shadow keyboard at all times (not just when enableHover),
        so that the keys underneath the fingers are readable.
        */}
        {/* 
        Since this is not a <button> element, a bunch of functionality for an accessible interactive button has to be restored, namely a tabindex and keydown/keyup event listeners 
        A good guide can be found here https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role
        */}
        {props.enableHover ? (
          <div
            className="shadow-keyboard"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onKeyDown={(event) => {
              // The default action for Space is to scroll the page a bit, so we should block that behaviour
              if (event.code === 'Space') {
                event.preventDefault();
              }
              // Note: not running onClick() in response to Enter press, because don't want to interfere
              // with keyboard functionality when animateOnKeypress is true
            }}
            onKeyUp={(event) => {
              if (event.code === 'Space' || event.code === 'Enter') {
                event.preventDefault();
                onClick();
              }
            }}
            onClick={onClick}
            tabIndex={0}
            aria-label={`${content} key`}
            {...roleButton}
          >
            <div className={classNames(shadowKlass, { active: props.active && !props.hideHands })}>
              {props.version === 1 ? (
                <div className="digit">{!props.hide ? processedContent : ''}</div>
              ) : (
                <div className="digit">
                  {/* .digit-stroke uses text-stroke css property which applies stroke to center of font, not outside. digit-fill is a copy of the letter
                  that sits on top of the letter with stroke applied, thus simulating an outside stroke effect */}
                  <div className="digit-stroke">{!props.hide ? processedContent : ''}</div>
                  <div className="digit-fill" aria-hidden="true">
                    {!props.hide ? processedContent : ''}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="shadow-keyboard">
            <div className={classNames(shadowKlass, { active: props.active && !props.hideHands })}>
              {props.version === 1 ? (
                <div className="digit">{!props.hide ? processedContent : ''}</div>
              ) : (
                <div className="digit">
                  {/* .digit-stroke uses text-stroke css property which applies stroke to center of font, not outside. digit-fill is a copy of the letter
                  that sits on top of the letter with stroke applied, thus simulating an outside stroke effect */}
                  <div className="digit-stroke">{!props.hide ? processedContent : ''}</div>
                  <div className="digit-fill" role="presentation">
                    {!props.hide ? processedContent : ''}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* 
        This div display finger animation images on the keyboard. 
      */}
      {props.img && (
        <div
          className={classNames('w-100', fingerImageContainer, {
            'd-none': !showImage,
            [hideFingerImageContainer]: props.hideHands,
          })}
          // style={{ position: 'absolute', zIndex: 2, top: '0px' }}
        >
          {props.version === 1 ? (
            <img
              src={props.img}
              className={`${fingerImage} ${hand === 'left' ? leftHand : rightHand}`}
              alt={`${hand} hand pressing the ${content} key`}
            />
          ) : (
            React.createElement(props.img, {
              title: `${hand} hand pressing the ${content} key`,
              className: `${hand === 'left' ? fingerImageLeft : fingerImageRight} ${props.skinTone}`,
            })
          )}
        </div>
      )}
    </>
  );
}

InteractiveKeyboardKey.propTypes = {
  // use the new style? if yes, uses different css
  version: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  enableHover: PropTypes.bool.isRequired,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  showImage: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  hide: PropTypes.bool,
  // Change the background of the current key using the color string value provided in this prop
  spotlightColor: PropTypes.string,
  skinTone: PropTypes.oneOf(['noSkinTone', 'lightSkinTone', 'mediumLightSkinTone', 'mediumDarkSkinTone', 'darkSkinTone']),
  hideHands: PropTypes.bool,
};

export default InteractiveKeyboardKey;
