import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './SkinToneSettings.module.scss';
const { skinToneSettings, active, icon } = styles;

// This is an expandable widget that floats above the keyboard and is used for changing skin tone settings
function SkinToneSettings(props) {
  // Skin tone options to choose from. Alt values are for tooltips and accessible aria labels
  const skinTones = [
    { alt: 'No Skin Tone', value: 'noSkinTone', color: '#FFD767' },
    { alt: 'Light Skin Tone', value: 'lightSkinTone', color: '#FCDDCD' },
    { alt: 'Medium Light Skin Tone', value: 'mediumLightSkinTone', color: '#ECBD96' },
    { alt: 'Medium Dark Skin Tone', value: 'mediumDarkSkinTone', color: '#A06940' },
    { alt: 'Dark Skin Tone', value: 'darkSkinTone', color: '#512D1A' },
  ];

  // tracks if the component is mounted which is needed since if the user changes state in angularjs
  // the callbacks to our API request could still run and try to update the state of an unmounted
  // component
  const mountedRef = useRef(true);

  const [expandedSettings, setExpandedSettings] = useState(false);
  const [savingState, setSavingState] = useState({ saving: false, error: false });

  useEffect(() => {
    return () => {
      if (mountedRef.current) mountedRef.current = false;
    };
  }, [mountedRef]);

  // function for updating user skin tone preferences on the server
  function changeSkinTone(value) {
    // collapse the skin tone options and show a saving animation
    setSavingState({ saving: true, error: false });
    setExpandedSettings(false);

    Promise.all([
      new Promise((accept) => setTimeout(accept, 2000)),
      fetch('/user_preference.json', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_preference: { skin_tone: value } }),
      }).then((res) => {
        if (!res.ok) return Promise.reject();
        return res.json();
      }),
    ])
      .then(() => {
        // return early if the component is not mounted. this is needed since if a user changes
        // state in angularjs, this callback will run to try to update the state of an unmounted component
        if (!mountedRef.current) return true;
        // update skin tone options in the parent prop, so that the hand images can be swapped
        props.changeSkinTone(value);
        // invoke the callback to enable angularjs to update the skintone
        props.skinToneUpdateCallback(value);
        setSavingState({ saving: false, error: false });
      })
      .catch(() => setSavingState({ saving: false, error: true }));
  }

  return (
    <div className="d-flex w-100">
      <div
        style={{ fontSize: props.scale + 'rem' }}
        className={classNames('d-flex mb-1 align-items-stretch justify-content-start', skinToneSettings, {
          [active]: expandedSettings,
        })}
      >
        <button
          className="btn p-0"
          style={{ fontSize: '1em' }}
          type="button"
          id="skinToneSettingsDropdown"
          aria-expanded={expandedSettings}
          aria-label="Choose Skin Tone"
          disabled={savingState.saving}
          onClick={() => setExpandedSettings((prev) => !prev)}
        >
          <i className="fa fa-cog text-secondary" aria-hidden="true"></i>
        </button>

        <ul
          aria-labelledby="skinToneSettingsDropdown"
          className={classNames('list-unstyled mb-0 d-flex mx-3 d-flex align-items-center')}
          aria-hidden={!expandedSettings}
        >
          {skinTones.map(({ alt, value, color }) => (
            <li key={value} className="mx-3 py-2" title={alt}>
              <button
                tabIndex={expandedSettings ? 0 : -1}
                aria-label={alt}
                className="btn p-0"
                onClick={() => {
                  changeSkinTone(value);
                }}
              >
                <div className={icon} style={{ backgroundColor: color }}></div>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="d-flex align-items-center text-secondary justify-content-end ms-auto">
        {savingState.saving ? (
          <>
            <div
              className="me-2 spinner-border"
              style={{ width: '0.75em', height: '0.75em', borderWidth: '0.15em' }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            <span className="small">Saving</span>
          </>
        ) : null}

        {!savingState.saving && savingState.error ? (
          <div role="alert" className="alert alert-warning px-1 py-0 m-0">
            Could not save settings.
          </div>
        ) : null}
      </div>
    </div>
  );
}

SkinToneSettings.propTypes = {
  changeSkinTone: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
  // callback is used primarily with angular to update the angularjs state
  skinToneUpdateCallback: PropTypes.func,
};

SkinToneSettings.defaultProps = {
  skinToneUpdateCallback: () => {},
};

export default SkinToneSettings;
