const defaultAttributes = { active: false, error: false };

const numberRow = {
  // these two keys share one physical key, so we only apply the default styles on
  // the first element (since they're concatenated in the React component)
  '`': { 'key key-styling letter-key ter': true, ...defaultAttributes },
  '~': { ...defaultAttributes },
  // these two keys share one key
  1: { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '!': { ...defaultAttributes },
  // these two keys share one key
  2: { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '@': { ...defaultAttributes },
  // these two keys share one key
  3: { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '#': { ...defaultAttributes },
  // these two keys share one key
  4: { 'key key-styling letter-key sec': true, ...defaultAttributes },
  $: { ...defaultAttributes },
  // these two keys share one key
  5: { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '%': { ...defaultAttributes },
  // these two keys share one key
  6: { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '^': { ...defaultAttributes },
  // these two keys share one key
  7: { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '&': { ...defaultAttributes },
  // these two keys share one key
  8: { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '*': { ...defaultAttributes },
  // these two keys share one key
  9: { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '(': { ...defaultAttributes },
  // these two keys share one key
  0: { 'key key-styling letter-key sec': true, ...defaultAttributes },
  ')': { ...defaultAttributes },
  //   these two keys share one key
  '-': { 'key key-styling letter-key sec': true, ...defaultAttributes },
  _: { ...defaultAttributes },
  // these two keys share one key
  '=': { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '+': { ...defaultAttributes },
  // last key in row
  backspace: { 'key key-styling backspace-key ter last-key': true, ...defaultAttributes },
};

const topRow = {
  tab: { 'key key-styling tab-key ter': true, ...defaultAttributes },
  q: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  w: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  e: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  r: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  t: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  y: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  u: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  i: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  o: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  p: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  // these two keys share one key
  '[': { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '{': { ...defaultAttributes },
  // these two keys share one key
  ']': { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '}': { ...defaultAttributes },
  // these two keys share one key
  '\\': { 'key key-styling letter-key sec last-key': true, ...defaultAttributes },
  '|': { ...defaultAttributes },
};

const middleRow = {
  capslock: { 'key key-styling capslock-key ter': true, ...defaultAttributes },
  a: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  s: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  d: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  f: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  g: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  h: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  j: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  k: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  l: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  // these two keys share one key
  ';': { 'key key-styling letter-key pri': true, ...defaultAttributes },
  ':': { ...defaultAttributes },
  // these two keys share one key
  "'": { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '"': { ...defaultAttributes },
  // these two keys share one key
  enter: { 'key key-styling enter-key ter last-key': true, ...defaultAttributes },
};

const bottomRow = {
  rightShift: { 'key key-styling right-shift-key ter last-key': true, ...defaultAttributes },
  leftShift: { 'key key-styling left-shift-key ter': true, ...defaultAttributes },
  shift: { 'key key-styling left-shift-key ter': true, ...defaultAttributes },
  z: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  x: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  c: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  v: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  b: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  n: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  m: { 'key key-styling letter-key pri': true, ...defaultAttributes },
  // these two keys share one key
  ',': { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '<': { ...defaultAttributes },
  // these two keys share one key
  '.': { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '>': { ...defaultAttributes },
  // these two keys share one key
  '/': { 'key key-styling letter-key sec': true, ...defaultAttributes },
  '?': { ...defaultAttributes },
};

const controlRow = {
  ctrl: { 'key key-styling ctrl-key ter': true, ...defaultAttributes },
  alt: { 'key key-styling alt-key ter': true, ...defaultAttributes },
  ' ': { 'key key-styling space-key ter': true, ...defaultAttributes },
};

const defaultKeys = {
  ...numberRow,
  ...topRow,
  ...middleRow,
  ...bottomRow,
  ...controlRow,
};

/**
 * Maps secondary (shift-modified) keys to their primary keys on the keyboard.
 * 
 * This object allows the keyboard to properly handle and visualize secondary characters
 * by mapping them to the physical keys they share with primary characters.
 * 
 * For example, '@' is typed using Shift+2, so it maps to '2' which is its primary key.
 * This mapping is used to:
 * 1. Properly highlight keys when secondary characters are typed
 * 2. Determine finger and hand assignments for secondary characters
 * 3. Apply consistent styling and behavior across all keyboard characters
 */
const secondaryKeys = {
  // number row
  '~': '`',
  '!': '1',
  '@': '2',
  '#': '3',
  '$': '4',
  '%': '5',
  '^': '6',
  '&': '7',
  '*': '8',
  '(': '9',
  ')': '0',
  '_': '-',
  '+': '=',
  
  // top row
  '{': '[',
  '}': ']',
  '|': '\\',
  
  // middle row
  ':': ';',
  '"': "'",
  
  // bottom row
  '<': ',',
  '>': '.',
  '?': '/',
};

const keyProperties = {
  // number row ------------------------------------------------------------
  '`': { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 'f', finger: 'pinky', xIndex: 0 },
  '~': { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 'f', finger: 'pinky', xIndex: 0 },
  1: { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 'a', finger: 'pinky', xIndex: 1 },
  '!': { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 'a', finger: 'pinky', xIndex: 1 },
  2: { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 's', finger: 'ring', xIndex: 2 },
  '@': { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 's', finger: 'ring', xIndex: 2 },
  3: { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 'd', finger: 'middle', xIndex: 3 },
  '#': { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 'd', finger: 'middle', xIndex: 3 },
  4: { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 'f', finger: 'index', xIndex: 4 },
  '$': { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 'f', finger: 'index', xIndex: 4 },
  5: { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 'f', finger: 'index', xIndex: 5 },
  '%': { rowName: 'number', hand: 'left', yIndex: 0, homerowKey: 'f', finger: 'index', xIndex: 5 },
  6: { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: 'j', finger: 'index', xIndex: 6 },
  '^': { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: 'j', finger: 'index', xIndex: 6 },
  7: { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: 'j', finger: 'index', xIndex: 7 },
  '&': { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: 'j', finger: 'index', xIndex: 7 },
  8: { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: 'k', finger: 'middle', xIndex: 8 },
  '*': { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: 'k', finger: 'middle', xIndex: 8 },
  9: { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: 'l', finger: 'ring', xIndex: 9 },
  '(': { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: 'l', finger: 'ring', xIndex: 9 },
  0: { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: ';', finger: 'pinky', xIndex: 10 },
  ')': { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: ';', finger: 'pinky', xIndex: 10 },
  '-': { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: ';', finger: 'pinky', xIndex: 11 },
  '_': { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: ';', finger: 'pinky', xIndex: 11 },
  '=': { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: ';', finger: 'pinky', xIndex: 12 },
  '+': { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: ';', finger: 'pinky', xIndex: 12 },
  backspace: { rowName: 'number', hand: 'right', yIndex: 0, homerowKey: ';', finger: 'pinky', xIndex: 13 },

  // top row ---------------------------------------------------------------
  tab: { rowName: 'top', hand: 'left', yIndex: 1, homerowKey: 'a', finger: 'pinky', xIndex: 0 },
  q: { rowName: 'top', hand: 'left', yIndex: 1, homerowKey: 'a', finger: 'pinky', xIndex: 1 },
  w: { rowName: 'top', hand: 'left', yIndex: 1, homerowKey: 's', finger: 'ring', xIndex: 2 },
  e: { rowName: 'top', hand: 'left', yIndex: 1, homerowKey: 'd', finger: 'middle', xIndex: 3 },
  r: { rowName: 'top', hand: 'left', yIndex: 1, homerowKey: 'f', finger: 'index', xIndex: 4 },
  t: { rowName: 'top', hand: 'left', yIndex: 1, homerowKey: 'f', finger: 'index', xIndex: 5 },
  y: { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: 'j', finger: 'index', xIndex: 6 },
  u: { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: 'j', finger: 'index', xIndex: 7 },
  i: { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: 'k', finger: 'middle', xIndex: 8 },
  o: { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: 'l', finger: 'ring', xIndex: 9 },
  p: { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: ';', finger: 'pinky', xIndex: 10 },
  '[': { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: ';', finger: 'pinky', xIndex: 11 },
  '{': { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: ';', finger: 'pinky', xIndex: 11 },
  ']': { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: ';', finger: 'pinky', xIndex: 12 },
  '}': { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: ';', finger: 'pinky', xIndex: 12 },
  '\\': { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: ';', finger: 'pinky', xIndex: 13 },
  '|': { rowName: 'top', hand: 'right', yIndex: 1, homerowKey: ';', finger: 'pinky', xIndex: 13 },

  // middle row ---------------------------------------------------------------
  capslock: { rowName: 'middle', hand: 'left', yIndex: 2, homerowKey: 'a', finger: 'pinky', xIndex: 0 },
  a: { rowName: 'middle', hand: 'left', yIndex: 2, finger: 'pinky', xIndex: 1 },
  s: { rowName: 'middle', hand: 'left', yIndex: 2, finger: 'ring', xIndex: 2 },
  d: { rowName: 'middle', hand: 'left', yIndex: 2, finger: 'middle', xIndex: 3 },
  f: { rowName: 'middle', hand: 'left', yIndex: 2, finger: 'index', xIndex: 4 },
  g: { rowName: 'middle', hand: 'left', yIndex: 2, homerowKey: 'f', finger: 'index', xIndex: 5 },
  h: { rowName: 'middle', hand: 'right', yIndex: 2, homerowKey: 'j', finger: 'index', xIndex: 6 },
  j: { rowName: 'middle', hand: 'right', yIndex: 2, finger: 'index', xIndex: 7 },
  k: { rowName: 'middle', hand: 'right', yIndex: 2, finger: 'middle', xIndex: 8 },
  l: { rowName: 'middle', hand: 'right', yIndex: 2, finger: 'ring', xIndex: 9 },
  ';': { rowName: 'middle', hand: 'right', yIndex: 2, finger: 'pinky', xIndex: 10 },
  ':': { rowName: 'middle', hand: 'right', yIndex: 2, finger: 'pinky', xIndex: 10 },
  "'": { rowName: 'middle', hand: 'right', yIndex: 2, homerowKey: ';', finger: 'pinky', xIndex: 11 },
  '"': { rowName: 'middle', hand: 'right', yIndex: 2, homerowKey: ';', finger: 'pinky', xIndex: 11 },
  enter: { rowName: 'middle', hand: 'right', yIndex: 2, homerowKey: ';', finger: 'pinky', xIndex: 12 },

  // bottom row ----------------------------------------------------------------
  rightShift: { rowName: 'bottom', hand: 'right', yIndex: 3, finger: 'pinky', xIndex: 0 },
  leftShift: { rowName: 'bottom', hand: 'left', yIndex: 3, finger: 'pinky', xIndex: 0 },
  shift: { rowName: 'bottom', hand: 'left', yIndex: 3, finger: 'pinky', xIndex: 0 },
  z: { rowName: 'bottom', hand: 'left', yIndex: 3, homerowKey: 'a', finger: 'pinky', xIndex: 1 },
  x: { rowName: 'bottom', hand: 'left', yIndex: 3, homerowKey: 's', finger: 'ring', xIndex: 2 },
  c: { rowName: 'bottom', hand: 'left', yIndex: 3, homerowKey: 'd', finger: 'middle', xIndex: 3 },
  v: { rowName: 'bottom', hand: 'left', yIndex: 3, homerowKey: 'f', finger: 'index', xIndex: 4 },
  b: { rowName: 'bottom', hand: 'left', yIndex: 3, homerowKey: 'f', finger: 'index', xIndex: 5 },
  n: { rowName: 'bottom', hand: 'right', yIndex: 3, homerowKey: 'j', finger: 'index', xIndex: 6 },
  m: { rowName: 'bottom', hand: 'right', yIndex: 3, homerowKey: 'j', finger: 'index', xIndex: 7 },
  ',': { rowName: 'bottom', hand: 'right', yIndex: 3, homerowKey: 'k', finger: 'middle', xIndex: 8 },
  '<': { rowName: 'bottom', hand: 'right', yIndex: 3, homerowKey: 'k', finger: 'middle', xIndex: 8 },
  '.': { rowName: 'bottom', hand: 'right', yIndex: 3, homerowKey: 'l', finger: 'ring', xIndex: 9 },
  '>': { rowName: 'bottom', hand: 'right', yIndex: 3, homerowKey: 'l', finger: 'ring', xIndex: 9 },
  '/': { rowName: 'bottom', hand: 'right', yIndex: 3, homerowKey: ';', finger: 'pinky', xIndex: 10 },
  '?': { rowName: 'bottom', hand: 'right', yIndex: 3, homerowKey: ';', finger: 'pinky', xIndex: 10 },

  // control row ----------------------------------------------------------------
  ctrl: { rowName: 'control', hand: 'left', yIndex: 4, finger: 'pinky', xIndex: 0 },
  ' ': { rowName: 'control', hand: 'right', yIndex: 4, finger: 'thumb', xIndex: 1 },
  alt: { rowName: 'control', hand: 'left', yIndex: 4, finger: 'pinky', xIndex: 2 },
};

export { defaultKeys, keyProperties, secondaryKeys };