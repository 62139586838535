import React from 'react';
import PropTypes from 'prop-types';
import InteractiveKeyboardKey from './InteractiveKeyboardKey/InteractiveKeyboardKey';
import fingersV1 from './data/FingerAnimationImages_v1';
import fingersV2 from './data/FingerAnimationImages_v2';
import { keyProperties } from './data/defaultKeys';
import styles from './InteractiveKeyboardKey/InteractiveKeyboardKey.module.scss';
const {
  fingerImage,
  fingerImageLeft,
  fingerImageRight,
  leftHand,
  rightHand,
  fingerImageContainer,
  hideFingerImageContainer,
  lowerFingerImageContainer,
  keyboardWidth,
  keyboardWidthV2,
  keyboardKey,
  keyboardKeyV2,
} = styles;
import classNames from 'classnames';
import KeyEventHandler from '../KeyEventHandler/KeyEventHandler';
import { processKeyPressed, isKeyUppercase, oppositeShiftKey } from './InteractiveKeyboardHelpers';
import SkinToneSettings from './SkinToneSettings/SkinToneSettings';

// Defining the variable here so that it is accessible in getDerivedPropsFromState, which does not have access to `this` since it's a static method
let fingers;
// This is only an object while we still have v1 of finger images. Phase it out once we fully switch to v2 keyboard
fingers = {
  1: fingersV1,
  2: fingersV2,
};

export default class InteractiveKeyboard extends React.Component {
  constructor(props) {
    super(props);
    this.keyEventHandler = new KeyEventHandler();
    // JS timeout object that gets assigned here after a user keypress  (if props.fadeKeypress is true)
    // This timeout is used to clear the keys after some time, to mimic a transient keypress
    this.fadeKeypressTimeout = null;
  }

  state = {
    // onkeyClick will populate this object. For example, if the 'a' key is clicked:
    // showImage: {a: true}
    showImage: { left: { home: false }, right: { home: false } },
    activeKeys: { left: {}, right: {} },
    // skinTone is default is set here instead of defaultProps to solve and angularJS
    // issue (see note in defaultProps)
    skinTone: this.props.skinTone || 'mediumLightSkinTone',
    showSettingsDropdown: false,
  };

  componentDidMount() {
    if (this.props.animateOnKeypress) {
      document.body.addEventListener('keydown', this.keydownHandler);
      document.body.addEventListener('keyup', this.keyupHandler);
    }
  }

  componentWillUnmount() {
    if (this.props.animateOnKeypress) {
      document.body.removeEventListener('keydown', this.keydownHandler);
      document.body.removeEventListener('keyup', this.keyupHandler);
    }
    if (this.fadeKeypressTimeout) clearTimeout(this.fadeKeypressTimeout);
  }

  // This method is called on each render. It checks to see if we have any keys that should be highlighted
  // and adds those from props to state to eliminate duplication
  static getDerivedStateFromProps(props, state) {
    if (Array.isArray(props.highlightKeys) && props.highlightKeys.length === 0) {
      // if an empty array of highlightKeys passed in, clear the keyboard
      return { ...state, showImage: { left: { home: true }, right: { home: true } }, activeKeys: { left: {}, right: {} } };
    }
    // If value is undefined, then don't do anything
    if (!props.highlightKeys) return state;

    let newState = { ...state };

    // Reset activeKeys and showImage in case there were highlighted keys from previous props
    newState.activeKeys = { left: {}, right: {} };
    newState.showImage = { left: { home: true }, right: { home: true } };
    props.highlightKeys
      .map((keyContent) => {
        // If detected a regular shift character, treat it as `leftShift`
        if (keyContent.toLowerCase() === 'shift') return 'leftShift';
        // If detected an uppercase character, add a shift key
        if (isKeyUppercase(keyContent)) {
          const letter = keyContent.toLowerCase();
          // Get the shift key on the opposite side of the keyboard
          const shift = oppositeShiftKey(letter);
          return [letter, shift];
        }
        // If a non-breaking space, treat it as a regular space char (eg. char used for space in the writing prompt is a zero-width space)
        if (keyContent === `\u00a0`) {
          return [' '];
        }
        return keyContent;
      })
      .flat()
      .forEach((keyContent) => {
        // Check if keyProperties exists for this keyContent
        if (!keyProperties[keyContent]) {
          // Log the error with details for Bugsnag to capture
          console.error(`Cannot read properties of undefined (reading 'hand')`, {
            keyContent,
            keyContentType: typeof keyContent,
            keyPropertiesKeys: Object.keys(keyProperties),
            highlightKeys: props.highlightKeys,
          });
          // return; // Skip this keyContent and continue with the next one
        }

        const hand = keyProperties[keyContent].hand;
        // if we don't have the image for this key, use the home row image
        newState.activeKeys[hand] = { [keyContent]: true };
        let key = fingers[props.version][keyContent] ? keyContent : 'home';
        newState.showImage[hand] = { [key]: true };
      });

    return newState;
  }

  // Change skin tone of hands over keyboard
  changeSkinTone = (skinTone) => {
    this.setState({ skinTone: skinTone });
  };

  // keydownHandler is only mounted if animateOnKeypress is true
  keydownHandler = (e) => {
    // gets an enhanced event object with keyPressed and keyPressedLowcase attributes
    const targetKey = ''; // doesn't matter... we only care about the key that was pressed
    this.keyEventHandler.getKeyPressed(e, targetKey);

    this.onKeyClick(processKeyPressed(e));
  };

  // remove the key from the list of activeKeys
  keyupHandler = (e) => {
    // don't do anything if we're pressing a key like command, etc.
    if (!keyProperties[keyContent]) return;
    // gets an enhanced event object with keyPressed and keyPressedLowcase attributes
    const targetKey = ''; // doesn't matter... we only care about the key that was pressed
    this.keyEventHandler.getKeyPressed(e, targetKey);
    const keyContent = e.keyPressedLowcase;
    const hand = keyProperties[keyContent].hand;
    let activeKeysState = { ...this.state.activeKeys[hand] };
    delete activeKeysState[keyContent];
    this.setState({ activeKeys: { ...this.state.activeKeys, [hand]: activeKeysState } });
  };

  // helper method to automatically pass needed attributes to each InteractiveKeyboardKey
  setInteractiveKeyAttrs = (key) => {
    const version = this.props.version;
    const img = fingers[version][key];
    let hide = (this.props.hiddenKeys || []).includes(key);
    const hand = keyProperties[key].hand;
    const showImage = this.state.showImage[hand][key] || false;
    const active = this.state.activeKeys[hand][key] || false;
    const enableHover = this.props.enableHover;
    let spotlightColor = this.props.spotlightKeys[key.toLowerCase()];
    // If the current key is a special key, (eg. leftShift should stay as is)
    if (key.length > 1) {
      spotlightColor = this.props.spotlightKeys[key];
    }
    // Adding an alias for `leftShift` to be `shift` if passed via spotlightKeys prop
    if (key === 'leftShift') {
      spotlightColor = this.props.spotlightKeys['leftShift'] || this.props.spotlightKeys['shift'];
      hide = (this.props.hiddenKeys || []).includes('leftShift') || (this.props.hiddenKeys || []).includes('shift');
    }
    const attrs = {
      content: key,
      hide,
      enableHover,
      img,
      onClick: this.onKeyClick,
      active,
      showImage,
      spotlightColor,
      version,
      skinTone: this.state.skinTone,
      hideHands: this.props.hideHands,
    };
    return attrs;
  };

  // callback method passed into each InteractiveKeyboardKey
  onKeyClick = (keyContent) => {
    // don't do anything if we're pressing a key like command, etc.
    if (!keyProperties[keyContent]) return;
    const hand = keyProperties[keyContent].hand;
    // if we don't have the image for this key, use the home row image
    const activeKeysUpdate = { [hand]: { [keyContent]: true } };
    let key = fingers[this.props.version][keyContent] ? keyContent : 'home';
    const showImageUpdate = { [hand]: { [key]: true } };
    this.setState({
      showImage: { ...this.state.showImage, ...showImageUpdate },
      activeKeys: { ...this.state.activeKeys, ...activeKeysUpdate },
    });

    // Clear the keyboard after half a second, to mimic a transient keypress
    if (this.props.fadeKeypress) {
      this.fadeKeypressTimeout = setTimeout(() => {
        this.setState({
          activeKeys: { left: {}, right: {} },
          showImage: { left: { home: true }, right: { home: true } },
        });
      }, 500);
    }
  };

  render() {
    const keyClass = this.props.version === 1 ? keyboardKey : keyboardKeyV2;

    return (
      <div style={{ paddingLeft: '1px' }}>
        {/* Added 1px of left padding to account for box-shadow fo the first key */}
        {/* Need a div other wise the layout (with gear) breaks with a flexbox container */}

        {/* Skin Tone Settings widget */}
        {this.props.version !== 1 && (
          <div className={`${keyboardWidthV2}`}>
            <SkinToneSettings
              changeSkinTone={this.changeSkinTone}
              scale={this.props.scale}
              skinToneUpdateCallback={this.props.skinToneUpdateCallback}
            />
          </div>
        )}

        <div style={{ position: 'relative' }}>
          {/* This keyboard is responsible for highlighting the background of the key */}
          {/* Using rem units for the top container so that it can't be accidentally scaled by font size of whatever parent html element it is in, and instead is controlled
          by the font size of the html document as a whole. All the other units in the inner html of this component are in `em` so that they are relative and everything can be scaled at the same time. */}
          <div
            className={`d-flex flex-wrap position-relative ${this.props.version === 1 ? keyboardWidth : keyboardWidthV2}`}
            style={{ paddingBottom: '10em', fontSize: this.props.scale + 'rem' }}
          >
            {/* Number Row */}
            {['`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', 'backspace'].map((key) => {
              return <InteractiveKeyboardKey key={key} {...this.setInteractiveKeyAttrs(key)} />;
            })}
            {/* End of Number Row */}

            {/* Top Row (tab row) */}
            {['tab', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\'].map((key) => {
              return <InteractiveKeyboardKey key={key} {...this.setInteractiveKeyAttrs(key)} />;
            })}
            {/* End of Top Row */}

            {/* Middle Row (capslock row) */}
            {['capslock', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', "'", 'enter'].map((key) => {
              return <InteractiveKeyboardKey key={key} {...this.setInteractiveKeyAttrs(key)} version={this.props.version} />;
            })}
            {/* End of Middle Row */}

            {/* Bottom Row */}
            {['leftShift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/', 'rightShift'].map((key) => {
              return <InteractiveKeyboardKey key={key} {...this.setInteractiveKeyAttrs(key)} />;
            })}
            {/* End of Bottom Row */}

            {/* Control Row */}
            {/* Note: make sure the components have unique key attributes, otherwise React might not re-render InteractiveKeyboardKey components
             when the version changes */}
            {this.props.version === 1 ? (
              <>
                <InteractiveKeyboardKey
                  key={'ctrl-v1'}
                  content={'ctrl'}
                  enableHover={this.props.enableHover}
                  version={this.props.version}
                />
                {/* empty key */}
                <div className={keyClass}>
                  <div className="key key-styling alt-key ter">
                    <div className="digit"></div>
                  </div>
                </div>
                <InteractiveKeyboardKey
                  key={'alt-v1'}
                  content={'alt'}
                  enableHover={this.props.enableHover}
                  version={this.props.version}
                />
                <InteractiveKeyboardKey key={'space-v1'} content={' '} {...this.setInteractiveKeyAttrs(' ')} />
                <div className={keyClass}>
                  <div className="key key-styling alt-key ter">
                    <div className="digit">alt</div>
                  </div>
                </div>
                <div className={keyClass}>
                  <div className="key key-styling alt-key ter">
                    <div className="digit"></div>
                  </div>
                </div>
                <div className={keyClass}>
                  <div className="key key-styling alt-key ter">
                    <div className="digit"></div>
                  </div>
                </div>

                <div className={keyClass}>
                  <div className="key key-styling ctrl-key ter last-key">
                    <div className="digit">ctrl</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* empty key - 'fn' */}
                <div className={keyClass}>
                  <div className="key key-styling letter-key ter">
                    <div className="digit"></div>
                  </div>
                </div>
                <InteractiveKeyboardKey key={'ctrl-v2'} content={'ctrl'} enableHover={false} version={this.props.version} />
                {/* empty key - 'windows key'*/}
                <div className={keyClass}>
                  <div className="key key-styling letter-key ter">
                    <div className="digit"></div>
                  </div>
                </div>
                <InteractiveKeyboardKey key={'alt-v2'} content={'alt'} enableHover={false} version={this.props.version} />
                <InteractiveKeyboardKey key={'space-v2'} content={' '} {...this.setInteractiveKeyAttrs(' ')} />

                {/* right alt*/}
                <InteractiveKeyboardKey key={'alt-2-v2'} content={'alt'} enableHover={false} version={this.props.version} />

                {/* right ctrl */}
                <InteractiveKeyboardKey key={'ctrl-2-v2'} content={'ctrl'} enableHover={false} version={this.props.version} />

                {/* left arrow */}
                <div className={keyClass}>
                  <div className="key key-styling ter arrow-left-key">
                    <div className="digit">
                      <i className="fa fa-caret-left" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                {/* up and down arrows */}
                <div className={keyClass}>
                  <div className="key up-key key-styling ter arrow-up-key">
                    <div className="digit">
                      <i className="fa fa-caret-up" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="key down-key key-styling key-6 ter arrow-down-key">
                    <div className="digit">
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                {/* right arrow */}
                <div className={keyClass}>
                  <div className="key key-styling ter arrow-right-key last-key">
                    <div className="digit">
                      <i className="fa fa-caret-right" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* End of Control Row */}

            {/* 
              These two hand images display the resting position in the home row
            */}
            <div
              className={classNames('w-100', fingerImageContainer, {
                'd-none': !this.state.showImage.left['home'],
                // The homerow hands show up
                // a. for the non-key-pressing hand when highlightKeys prop has a letter in it (want just the lower opacity animation when hideHands is true)
                // b. when highlightKeys is an empty array and we are showing both homerow hands (want both lower opacity animation and rise hands when hideHands is true)
                [hideFingerImageContainer]: this.props.hideHands,
                [lowerFingerImageContainer]:
                  this.props.hideHands && Array.isArray(this.props.highlightKeys) && this.props.highlightKeys.length === 0,
              })}
            >
              {this.props.version === 1 ? (
                <img
                  src={fingers[this.props.version]['leftHome']}
                  className={`${fingerImage} ${leftHand}`}
                  alt={`left hand resting in the home row position`}
                />
              ) : (
                React.createElement(fingers[this.props.version]['leftHome'], {
                  title: `left hand resting in the home row position`,
                  className: `${fingerImageLeft} ${this.state.skinTone}`,
                })
              )}
            </div>
            <div
              className={classNames('w-100', fingerImageContainer, {
                'd-none': !this.state.showImage.right['home'],
                // The homerow hands show up
                // a. for the non-key-pressing hand when highlightKeys prop has a letter in it (want just the lower opacity animation when hideHands is true)
                // b. when highlightKeys is an empty array and we are showing both homerow hands (want both lower opacity animation and rise hands when hideHands is true)
                [hideFingerImageContainer]: this.props.hideHands,
                [lowerFingerImageContainer]:
                  this.props.hideHands && Array.isArray(this.props.highlightKeys) && this.props.highlightKeys.length === 0,
              })}
            >
              {this.props.version === 1 ? (
                <img
                  src={fingers[this.props.version]['rightHome']}
                  className={`${fingerImage} ${rightHand}`}
                  alt={`right hand resting in the home row position`}
                />
              ) : (
                React.createElement(fingers[this.props.version]['rightHome'], {
                  alt: `right hand resting in the home row position`,
                  className: `${fingerImageRight} ${this.state.skinTone}`,
                })
              )}
            </div>
            {/* End of home row hands */}
          </div>
        </div>
      </div>
    );
  }
}

InteractiveKeyboard.defaultProps = {
  // Note: skinTone is not included here as we set the default value
  // in the state variable. We do this since angularJS doesn't pass undefined, but
  // rather null, and our default props do not override null.
  fadeKeypress: false,
  spotlightKeys: {},
  scale: 1,
  version: 1,
  hideHands: false,
};

// Note: in `hiddenKeys`, `highlightKeys`, and `spotlightKeys`
// 'shift' keys can be specified as `shiftLeft`, `shiftRight`, or `shift` (internally converted to `shiftLeft`)
InteractiveKeyboard.propTypes = {
  enableHover: PropTypes.bool.isRequired,
  animateOnKeypress: PropTypes.bool,
  // An undefined value will be ignored, an empty array will cause a the previously highlighted keys to be cleared
  highlightKeys: PropTypes.array,
  hiddenKeys: PropTypes.array,
  // If true, fades the user-pressed key after a short period of time, to mimic a transient keypress
  fadeKeypress: PropTypes.bool,
  // Unlike the highlightLetters which changes the finger animation as well, this just specifies any number of keys to highlight, using specific colors provided
  // eg. {'a': '#001122', 'b': '#00ffff'}
  spotlightKeys: PropTypes.objectOf(PropTypes.string),
  version: PropTypes.number.isRequired,
  scale: PropTypes.number, // How much to scale the entire keyboard + hands by
  skinTone: PropTypes.oneOf(['noSkinTone', 'lightSkinTone', 'mediumLightSkinTone', 'mediumDarkSkinTone', 'darkSkinTone']),
  skinToneUpdateCallback: PropTypes.func,
  hideHands: PropTypes.bool,
};
