// Utility functions
const keycodeMap = {
  8: 'Backspace',
  9: 'Tab',
  13: 'Enter',
  16: 'Shift',
  17: 'Control',
  18: 'Alt',
  20: 'CapsLock',
  32: ' ',
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  59: ';', //this is a hack. have to stop using keycodes
  96: '0', //numpad
  97: '1', //numpad
  98: '2', //numpad
  99: '3', //numpad
  100: '4', //numpad
  101: '5', //numpad
  102: '6', //numpad
  103: '7', //numpad
  104: '8', //numpad
  105: '9', //numpad
  91: 'Meta',
  173: '-',
  186: ';',
  187: '=',
  188: ',',
  189: '-',
  190: '.',
  191: '/',
  192: '`',
  219: '[',
  220: '\\',
  221: ']',
  222: "'",
};

// Utility functions
const keycodeMapWithShift = {
  8: 'Backspace',
  9: 'Tab',
  13: 'Enter',
  16: 'Shift',
  17: 'Control',
  18: 'Alt',
  20: 'CapsLock',
  32: ' ',
  34: '"', //this is a hack. have to stop using keycodes
  48: ')',
  49: '!',
  50: '@',
  51: '#',
  52: '$',
  53: '%',
  54: '^',
  55: '&',
  56: '*',
  57: '(',
  59: ':', //this is a hack. have to stop using keycodes
  96: '0', //numpad
  97: '1', //numpad
  98: '2', //numpad
  99: '3', //numpad
  100: '4', //numpad
  101: '5', //numpad
  102: '6', //numpad
  103: '7', //numpad
  104: '8', //numpad
  105: '9', //numpad
  91: 'Meta',
  173: '_',
  186: ':',
  187: '+',
  188: '<',
  189: '_',
  190: '>',
  191: '?',
  192: '~',
  219: '{',
  220: '|',
  221: '}',
  222: '"',
};

export { keycodeMap, keycodeMapWithShift };
