// helper file to import all the finger animation images we need from the asset pipeline
// and map them to useable filenames

import * as fingers from '~Images/finger_animations_react/index.js';

export default {
  // shift keys
  shift: fingers.LeftShift,
  leftShift: fingers.LeftShift,
  rightShift: fingers.RightShift,

  // special chars
  '`': fingers.Backtick,
  1: fingers.Num1,
  2: fingers.Num2,
  3: fingers.Num3,
  4: fingers.Num4,
  5: fingers.Num5,
  6: fingers.Num6,
  7: fingers.Num7,
  8: fingers.Num8,
  9: fingers.Num9,
  0: fingers.Num0,
  '-': fingers.MinusSign,
  '=': fingers.EqualSign,

  "'": fingers.Apostrophe,
  // ';': semiColon,
  '/': fingers.ForwardSlash,
  '.': fingers.Period,
  ',': fingers.Comma,
  enter: fingers.Enter,
  '[': fingers.LeftSquareBracket,
  ']': fingers.RightSquareBracket,
  '\\': fingers.BackSlash,
  tab: fingers.Tab,

  // NOTE: homerow keys are ignored, so that we are not rendering multiple versions of the same hand onto the screen, which would mess up the
  // linear gradient mapping inside the svgs
  // (homerow hands are always rendered anyways, with just display set to none, and become visible for keys that are not mapped in this file,
  // meaning a,s,d,f, and j,k,l,; keys do not need to be defined here because they are using the same hand svgs as homerow resting position)

  // normal chars
  leftHome: fingers.LeftHome,
  q: fingers.Q,
  capslock: fingers.Capslock,
  // a,
  z: fingers.Z,
  w: fingers.W,
  // s,
  x: fingers.X,
  e: fingers.E,
  // d,
  c: fingers.C,
  r: fingers.R,
  // f,
  v: fingers.V,
  t: fingers.T,
  g: fingers.G,
  b: fingers.B,
  rightHome: fingers.RightHome,
  p: fingers.P,
  backspace: fingers.Delete,
  o: fingers.O,
  // l,
  i: fingers.I,
  // k,
  u: fingers.U,
  // j,
  m: fingers.M,
  y: fingers.Y,
  h: fingers.H,
  n: fingers.N,
  // ' '
};
