import { keyProperties } from './data/defaultKeys';

// This function takes a keyboard event `e` and returns the e.keyPressed
// with some of the values modified (eg. shift key is returned as either leftShift or rightShift)
export function processKeyPressed(e) {
  switch (e.keyPressedLowcase) {
    case 'shift':
      if (e.location === e.DOM_KEY_LOCATION_RIGHT) {
        return 'rightShift';
      }
      return 'leftShift';
    case '↵':
      return 'enter';
    default:
      return e.keyPressedLowcase;
  }
}

// given a key (string), returns if the key is a capital letter. If a non-letter is passed in (eg. ';'), returns false.
export function isKeyUppercase(key = '') {
  // Adding a comparison to lower case as well, this way this conditional works with non letter keys, like ';', etc
  return key.toUpperCase() === key && key.toLowerCase() !== key;
}

// given a key (string), returns the shift key ('leftShift' or 'rightShift') that is on the opposite side of the keyboard
export function oppositeShiftKey(key) {
  return keyProperties[key.toLowerCase()].hand === 'right' ? 'leftShift' : 'rightShift';
}
